<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="text-center">
				<h1>
					Eigen
				</h1>
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12">
				<v-simple-table dense height="100">
					<template v-slot:default>
						<tbody>
            <tr>
              <td>Klantnummer</td>
              <td>{{ "#" + user.id }}</td>
            </tr>
						<tr>
							<td>Voornaam</td>
							<td>{{ user.firstname }}</td>
						</tr>
						<tr>
							<td>Achternaam</td>
							<td>{{ user.lastname }}</td>
						</tr>
						<tr>
							<td>Geboortedatum</td>
							<td>{{ user.date_of_birth }}</td>
						</tr>
						<tr>
							<td>Organisatie</td>
							<td>{{ user.organisation }}</td>
						</tr>
						<tr>
							<td>Team</td>
							<td>{{ user.selected_team.name }}</td>
						</tr>
						<tr>
							<td>Funcie/Rol</td>
							<td>{{ user.function }}</td>
						</tr>

						<tr>
							<td>Emailadres</td>
							<td>{{ user.email }}</td>
						</tr>
						<tr>
							<td>Wachtwoord</td>
							<td>**********</td>
						</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>

			<v-col cols="12" class="text-right py-0">
				<v-btn color="accent" class="" @click="editProfile">Wijzigen</v-btn>
			</v-col>

			<v-col cols="12" class="text-center">
				<h1>
					Analyses
				</h1>
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12" class="text-center py-0">
				<v-row justify="center" align="center" no-gutters>
					<v-col cols="3" @click="openGQS">
						<img src="../../assets/own/GQS-box.svg" height="40px" alt="Gedrag Quick Scan">
					</v-col>
					<v-col cols="6" @click="openGQS">
						Gedrag Quick Scan
					</v-col>
					<v-col cols="3" @click="openGQS">
						<v-icon color="green" v-if="scan.gqs.access && scan.gqs.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="scan.gqs.access && !scan.gqs.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="!scan.gqs.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openDQS">
						<img src="../../assets/own/DQS-box.svg" height="40px" alt="Driver Quick Scan">
					</v-col>
					<v-col cols="6" @click="openDQS">
						Driver Quick Scan
					</v-col>
					<v-col cols="3" @click="openDQS">
						<v-icon color="green" v-if="scan.dqs.access && scan.dqs.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="scan.dqs.access && !scan.dqs.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="!scan.dqs.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openTCA">
						<img src="../../assets/own/TCA-box.svg" height="40px" alt="Talent Competentie Analyse">
					</v-col>
					<v-col cols="6" @click="openTCA">
						Talent Competentie Analyse
					</v-col>
					<v-col cols="3" @click="openTCA">
						<v-icon color="green" v-if="scan.tca.access && scan.tca.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="scan.tca.access && !scan.tca.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="!scan.tca.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openGTP">
						<img src="../../assets/own/GTP-box.svg" height="40px" alt="Gedrag Team Profiel">
					</v-col>
					<v-col cols="6" @click="openGTP">
						Gedrag Team Profiel
					</v-col>
					<v-col cols="3" @click="openGTP">
						<v-icon color="grey" v-if="!user.selected_team.rapport_access">fas fa-lock</v-icon>
						<v-icon color="green" v-if="user.selected_team.rapport_access && scan.gtp.access && scan.gtp.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="user.selected_team.rapport_access && scan.gtp.access && !scan.gtp.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="user.selected_team.rapport_access && !scan.gtp.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openDTP">
						<img src="../../assets/own/DTP-box.svg" height="40px" alt="Drivers Team Profiel">
					</v-col>
					<v-col cols="6" @click="openDTP">
						Drivers Team Profiel
					</v-col>
					<v-col cols="3" @click="openDTP">
						<v-icon color="grey" v-if="!user.selected_team.rapport_access">fas fa-lock</v-icon>
						<v-icon color="green" v-if="user.selected_team.rapport_access && scan.dtp.access && scan.dtp.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="user.selected_team.rapport_access && scan.dtp.access && !scan.dtp.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="user.selected_team.rapport_access && !scan.dtp.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openTFQS">
						<img src="../../assets/own/TFQS-box.svg" height="40px" alt="TFQS Profiel">
					</v-col>
					<v-col cols="6" @click="openTFQS">
						Team Functies Quick Scan
					</v-col>
					<v-col cols="3" @click="openTFQS">
						<v-icon color="grey" v-if="!user.selected_team.rapport_access">fas fa-lock</v-icon>
						<v-icon color="green" v-if="user.selected_team.rapport_access && scan.tfqs.access && scan.tfqs.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="user.selected_team.rapport_access && scan.tfqs.access && !scan.tfqs.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="user.selected_team.rapport_access && !scan.tfqs.access">fa fa-times-circle</v-icon>
					</v-col>

					<v-col cols="3" @click="openEGO">
						<img src="../../assets/own/EGO-box.svg" height="40px" alt="PCM Profiel">
					</v-col>
					<v-col cols="6" @click="openEGO">
						EGO analyse
					</v-col>
					<v-col cols="3" @click="openEGO">
						<v-icon color="green" v-if="scan.ego.access && scan.ego.completed">fa fa-check-circle</v-icon>
						<v-icon color="accent" v-if="scan.ego.access && !scan.ego.completed">fa fa-hourglass-start</v-icon>
						<v-icon color="red" v-if="!scan.ego.access">fa fa-times-circle</v-icon>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" class="text-center">
				<h1>
					Documenten
				</h1>
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12" class="text-center py-0">
				Hieronder zie je alle <b>persoonlijke</b> documenten die voor je klaar staan. Druk op de naam om te downloaden.
				<v-row justify="center" align="center" v-if="document.length > 0">
					<v-col v-for="doc in document" :key="doc.id" cols="12" sm="6" md="4" @click="downloadDocument(doc)" class="cursor-pointer">
						<v-icon style="font-size: 40px">fa fa-file-alt</v-icon>
						<br>
						{{ doc.name }}<br>
						<small>{{ doc.created_at }}</small>
					</v-col>
				</v-row>
				<v-col cols="12" class="text-center" v-if="document.length === 0">
					Er zijn nog geen persoonlijke documenten voor je aangemaakt.
				</v-col>
			</v-col>

			<v-col cols="12" class="text-center py-0">
				<v-divider></v-divider>
				Hieronder zie je alle <b>team</b> documenten die voor je klaar staan. Druk op de naam om te downloaden.
				<v-row justify="center" align="center" v-if="teamDocuments.length > 0">
					<v-col v-for="doc in teamDocuments" :key="doc.id" cols="12" sm="6" md="4" @click="teamDownloadDocument(doc)" class="cursor-pointer">
						<v-icon style="font-size: 40px">fa fa-file-alt</v-icon>
						<br>
						{{ doc.name }}<br>
						<small>{{ doc.created_at }}</small>
					</v-col>
				</v-row>
				<v-col cols="12" class="text-center" v-if="teamDocuments.length === 0">
					Er zijn nog geen team documenten voor je aangemaakt.
				</v-col>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	name: "dashboardOwn",
	data() {
		return {}
	},
	computed: {
		...mapGetters({
			token: "auth/accessToken",
			user: "user/user",
			scan: "user/scan",
			document: "user/document",
			teamDocuments: "user/teamDocuments"
		}),
	},
	methods: {
		editProfile() {
			this.$router.push({name: "changePersonalInformation"})
		},
		openGQS() {
			this.$router.push({name: "dashboardOwnScanGQS"})
		},
		openDQS() {
			this.$router.push({name: "dashboardOwnScanDQS"})
		},
		openTCA() {
			this.$router.push({name: "dashboardOwnScanTCA"})
		},
		openGTP() {
			this.$router.push({name: "dashboardOwnScanGTP"})
		},
		openDTP() {
			this.$router.push({name: "dashboardOwnScanDTP"})
		},
		openEGO() {
			this.$router.push({name: "dashboardOwnScanEGO"})
		},
		openTFQS() {
			this.$router.push({name: "dashboardOwnScanTFQS"})
		},
		downloadDocument(doc) {
			let downloadUrl = process.env.VUE_APP_API_URL + "document/download/" + doc.token + ".pdf?token=" + this.token;

			window.open(downloadUrl, "_blank");
		},
		teamDownloadDocument(doc) {
			let downloadUrl = process.env.VUE_APP_API_URL + "document/team/download/" + doc.token + ".pdf?token=" + this.token;

			window.open(downloadUrl, "_blank");
		},
	}
};
</script>
